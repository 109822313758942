import {useMemo} from "react";
import {useFetchCareEntries} from "@queries/useFetchCareEntries";
import {ExtendedCareEntrySearchResult} from "@components/CareEntryRow";

const emptyList: string[] = [];

/**
 * Fetch all the related topics and cares for a given care entry.
 */
export default function useRelatedCares(care?: ExtendedCareEntrySearchResult) {
  const relatedTopicSlugs: string[] = useMemo(
    () => care?.related_topics?.map(relatedTopic => relatedTopic.slug) || emptyList,
    [care?.related_topics],
  );
  const careEntryQuery = useFetchCareEntries(care ? relatedTopicSlugs : []);
  const data = useMemo(
    () => [...(care ? careEntryQuery.data : []), ...(care?.cares || [])],
    [care, careEntryQuery.data],
  );
  return {...careEntryQuery, data};
}
