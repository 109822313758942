import {useMemo} from "react";

import {formatCareEntryResponse, FormattedCareEntryResponse} from "../utils/cms/cms";
import {FetchCareEntryResponse} from "@services/monolith/careEntries";
import {getFetchCareUrl} from "@services/monolith/careEntries/getFetchCareUrl";

import {CareEntrySearchResult} from "@services/monolith/careEntries";
import {jsonBody} from "@utils/fetch/responseBodies";
import batch from "@utils/fetch/batch";
import useSWR from "swr";
import monolith from "@utils/fetch/fetches";

const fetcher = jsonBody<FetchCareEntryResponse>(monolith());
const batchFetcher = batch(fetcher);

const maybeGetContent = (formattedCareEntryResponses: FormattedCareEntryResponse[]) =>
  formattedCareEntryResponses.reduce<CareEntrySearchResult[]>(
    (acc, next) => (!next ? acc : [...acc, next.content]),
    [],
  );

export const useFetchRawCareEntries = (slugs: string[]) => {
  const urls = slugs.length === 0 ? null : slugs.map(getFetchCareUrl);
  return useSWR<FetchCareEntryResponse[]>(urls, batchFetcher, {
    revalidateOnFocus: false,
  });
};

export const useFetchCareEntries = (slugs: string[]) => {
  const {data, ...others} = useFetchRawCareEntries(slugs);
  const formattedCareEntryResponses = useMemo(
    () => maybeGetContent(data?.map(formatCareEntryResponse) ?? []),
    [data],
  );

  return {data: formattedCareEntryResponses, ...others};
};
