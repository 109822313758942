import React from "react";

import {useTranslation} from "@i18n/client";

export const PhoneNumber = ({
  phoneNumber,
  onClick,
}: {
  phoneNumber: string;
  onClick?: () => void;
}): React.ReactElement => {
  const {t} = useTranslation();
  return (
    <li className="flex justify-center font-ttm pt-4 pb-2">
      <span className="mr-2">{t("Or call to book")}</span>
      <a
        onClick={onClick}
        href={`tel:${phoneNumber}`}
        className="underline text-darkerBlue hover:text-darkerBlue "
      >
        {phoneNumber}
      </a>
    </li>
  );
};
